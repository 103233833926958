























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class ChangePasswordPopup extends Vue {
  @Prop() popupId!: string
  @Prop({ default: true }) askForOld!: boolean
  @Prop() userId!: number
  public oldpass: string = ''
  public password: string = ''
  public confirm: string = ''
  public busy: boolean = false

  changePassword () {
    this.busy = true
    this.$validator.validateAll('change-pass').then((result) => {
      if (result) {
        if (this.password === this.confirm) {
          let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
          let params = {}
          if (this.askForOld) {
            params = { id: this.userId, old: this.oldpass, confirm: this.confirm, newp: this.password }
          } else {
            params = { id: this.userId, confirm: this.confirm, newp: this.password }
          }
          this.$store.dispatch('updatePassword', params).then(() => {
            Object.assign(this.$data, (this.$options.data! as any).apply(this))
            popupCloseButton.click()
            this.$notify({
              title: 'Password Changed',
              text: 'Password Updated Successfully!',
              type: 'success'
            })
            this.$validator.errors.clear()
          }, (response: any) => {
            if (response.body.error === 'invalid_credentials') {
              this.$notify({ text: 'Current Password is incorrect', type: 'error' })
            } else {
              var message = response.body[Object.keys(response.body)[0]][0]
              if (message) {
                this.$notify({
                  text: message,
                  type: 'error'
                })
              }
            }
          })
        } else {
          let error = 'New Password and Confirm Password should be the same'
          let field = this.$validator.fields.find({ name: 'Confirm Password', scope: 'change-pass' })
          this.$validator.errors.add({
            id: field!.id,
            field: 'Confirm Password',
            msg: error,
            scope: 'change-pass'
          })

          // field!.setFlags({
          //   invalid: true,
          //   valid: false,
          //   validated: true
          // })
          this.$notify({ text: error, type: 'warn' })
        }
      } else if (this.$validator.errors.has('change-pass.New Password:regex')) {
        this.$notify({ text: 'New password should contain at least one upper case, lower case and special characters', type: 'error' })
      } else {
        this.$notify({ text: this.$validator.errors.all('change-pass')[0], type: 'warn' })
      }
      this.busy = false
    }, () => {
      this.busy = false
    })
  }
}
