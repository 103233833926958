var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v("Change Password")]),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { "data-vv-scope": "change-pass" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.changePassword($event)
                  }
                }
              },
              [
                _vm.askForOld
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.oldpass,
                          expression: "oldpass"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has("change-pass.Old password")
                      },
                      attrs: {
                        type: "password",
                        autocomplete: "old-password",
                        placeholder: "Old Password",
                        name: "Old password"
                      },
                      domProps: { value: _vm.oldpass },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.oldpass = $event.target.value
                        }
                      }
                    })
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        min: 8,
                        regex:
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*])(?=.{8,})"
                      },
                      expression:
                        "{ required: true, min: 8, regex: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\\$%\\^&\\*])(?=.{8,})' }"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("change-pass.New Password")
                  },
                  attrs: {
                    type: "password",
                    autocomplete: "new-password",
                    placeholder: "New Password",
                    name: "New Password"
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                }),
                _c("div", {
                  staticClass: "focus-tip",
                  attrs: {
                    title:
                      "At least 8 characters, with at least 1 upper case, 1 lower case and 1 special character / number."
                  }
                }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.confirm,
                      expression: "confirm"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { required: true },
                      expression: "{ required: true }"
                    }
                  ],
                  class: {
                    input: true,
                    "is-danger": _vm.errors.has("change-pass.Confirm Password")
                  },
                  attrs: {
                    type: "password",
                    autocomplete: "new-password",
                    placeholder: "Confirm New Password",
                    name: "Confirm Password"
                  },
                  domProps: { value: _vm.confirm },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.confirm = $event.target.value
                    }
                  }
                }),
                _c("input", {
                  staticClass: "btn-lg-green",
                  attrs: { type: "submit", value: "SUBMIT", disabled: _vm.busy }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }